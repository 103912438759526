import { reducer as form } from 'redux-form';
import auth from '../redux/reducers/auth';
import { routerReducer as router } from 'react-router-redux';
import companies from '../redux/reducers/companies';
import profile from '../redux/reducers/profile';
import requests from '../redux/reducers/general/requests';
import localization from '../redux/reducers/localization';
import { combineReducers } from 'redux';
import table_all_companies from '../redux/reducers/table/all_companies';
import table_address_book_companies from '../redux/reducers/table/address_book_companies';
import table_address_book_contacts from '../redux/reducers/table/address_book_contacts';
import table_partner_companies from '../redux/reducers/table/partner_companies';
import table_partner_members from '../redux/reducers/table/partner_members';
import table_my_company_members from '../redux/reducers/table/my_company_members';
import table_all_projects from '../redux/reducers/table/all_projects';
import sidebar from '../redux/reducers/general/sidebar';
import active_modules from '../redux/reducers/general/active_modules';
import dropdown from '../redux/reducers/general/dropdown';
import overlay from '../redux/reducers/general/overlay';
import preloaders from '../redux/reducers/general/preloaders';
import notification from '../redux/reducers/general/notification';
import plus from '../redux/reducers/general/plus';
import table_stages from '../redux/reducers/table/stages';
import table_stage_disciplines from '../redux/reducers/table/stage_disciplines';
import table_teams from '../redux/reducers/table/teams';
import table_notifications from '../redux/reducers/table/notifications';
import table_plan_uploads from '../redux/reducers/table/plan_uploads';
import table_working_set_plans from '../redux/reducers/table/working_set_plans';
import table_my_deliveries from '../redux/reducers/table/my_deliveries';
import table_public_deliveries from '../redux/reducers/table/public_deliveries';
import table_plan_approvals from '../redux/reducers/table/plan_approvals';
import table_specification_approvals from '../redux/reducers/table/specification_approvals';
import autosave from '../redux/reducers/general/autosave';
import multiedit from '../redux/reducers/general/multiedit';
import downloader from '../redux/reducers/general/downloader';
import alertbox from '../redux/reducers/general/alertbox';
import table_current_set_plans from '../redux/reducers/table/current_set_plans';
import table_delivery_plans from '../redux/reducers/table/delivery_plans';
import table_delivery_specifications from '../redux/reducers/table/delivery_specifications';
import table_all_tasks from '../redux/reducers/table/all_tasks';
import table_all_meetings from '../redux/reducers/table/all_meetings';
import table_products from '../redux/reducers/table/products';
import table_working_set_specifications from '../redux/reducers/table/working_set_specifications';
import table_working_set_boq from '../redux/reducers/table/working_set_boq';
import table_current_set_specifications from '../redux/reducers/table/current_set_specifications';
import table_my_specification_deliveries from '../redux/reducers/table/my_specification_deliveries';
import table_public_specification_deliveries from '../redux/reducers/table/public_specification_deliveries';
import table_documents from '../redux/reducers/table/documents';
import table_sites from '../redux/reducers/table/sites';
import table_buildings from '../redux/reducers/table/buildings';
import table_storeys from '../redux/reducers/table/storeys';
import table_zones from '../redux/reducers/table/zones';
import table_expansion_joints from '../redux/reducers/table/expansion_joints';
import table_entrances from '../redux/reducers/table/entrances';
import table_rooms from '../redux/reducers/table/rooms';
import table_room_groups from '../redux/reducers/table/room_groups';
import table_room_types from '../redux/reducers/table/room_types';
import table_ocr_templates from '../redux/reducers/table/ocr_templates';
import table_brands from '../redux/reducers/table/brands';
import table_public_brands from '../redux/reducers/table/public_brands';
import lightbox from '../redux/reducers/general/lightbox';
import maintenance_mode from '../redux/reducers/general/maintenance_mode';
import superadmin_companies from '../redux/reducers/table/superadmin/companies';
import superadmin_users from '../redux/reducers/table/superadmin/users';
import superadmin_materials from '../redux/reducers/table/superadmin/materials';
import superadmin_raw_materials from '../redux/reducers/table/superadmin/raw_materials';
import superadmin_languages from '../redux/reducers/table/superadmin/languages';
import superadmin_translations from '../redux/reducers/table/superadmin/translations';
import superadmin_note_categories from '../redux/reducers/table/superadmin/note_categories';
import superadmin_notes from '../redux/reducers/table/superadmin/notes';
import superadmin_colors from '../redux/reducers/table/superadmin/colors';
import superadmin_paper_sizes from '../redux/reducers/table/superadmin/paper_sizes';
import superadmin_product_attribute_template_groups from '../redux/reducers/table/superadmin/product_attribute_template_groups';
import superadmin_product_attribute_template_fields from '../redux/reducers/table/superadmin/product_attribute_template_fields';
import superadmin_countries from '../redux/reducers/table/superadmin/countries';
import superadmin_regions from '../redux/reducers/table/superadmin/regions';
import superadmin_units from '../redux/reducers/table/superadmin/units';
import superadmin_unit_groups from '../redux/reducers/table/superadmin/unit_groups';
import superadmin_building_types from '../redux/reducers/table/superadmin/building_types';
import superadmin_functional_spaces from '../redux/reducers/table/superadmin/functional_spaces';
import superadmin_disciplines from '../redux/reducers/table/superadmin/disciplines';
import superadmin_raw_colors from '../redux/reducers/table/superadmin/raw_colors';
import superadmin_product_categories from '../redux/reducers/table/superadmin/product_categories';
import superadmin_product_groups from '../redux/reducers/table/superadmin/product_groups';
import superadmin_product_subgroups from '../redux/reducers/table/superadmin/product_subgroups';
import superadmin_specification_groups from '../redux/reducers/table/superadmin/specification_groups';
import superadmin_specification_subgroups from '../redux/reducers/table/superadmin/specification_subgroups';
import superadmin_boq_template_groups from '../redux/reducers/table/superadmin/boq_template_groups';
import superadmin_boq_template_subgroups from '../redux/reducers/table/superadmin/boq_template_subgroups';
import superadmin_boq_template_calculations from '../redux/reducers/table/superadmin/boq_template_calculations';
import superadmin_boq_supply_types from '../redux/reducers/table/superadmin/boq_supply_types';
import superadmin_boq_supply_type_groups from '../redux/reducers/table/superadmin/boq_supply_type_groups';
import superadmin_filters from '../redux/reducers/table/superadmin/filters';
import superadmin_products from '../redux/reducers/table/superadmin/products';
import table_import_spreadsheet from '../redux/reducers/table/import_spreadsheet';
import table_tenders from '../redux/reducers/table/tenders';
import table_specification_sets from '../redux/reducers/table/specification_sets';
import table_all_shared_models from '../redux/reducers/table/all_shared_models';

export default {
  router,
  form,
  auth,
  general: combineReducers({
    sidebar,
    dropdown,
    notification,
    plus,
    active_modules,
    preloaders,
    overlay,
    autosave,
    multiedit,
    downloader,
    alertbox,
    lightbox,
    maintenance_mode,
    requests,
  }),
  companies,
  profile,
  localization,
  table: combineReducers({
    all_companies: table_all_companies,
    address_book_companies: table_address_book_companies,
    address_book_contacts: table_address_book_contacts,
    partner_companies: table_partner_companies,
    partner_members: table_partner_members,
    my_company_members: table_my_company_members,
    all_projects: table_all_projects,
    stages: table_stages,
    stage_disciplines: table_stage_disciplines,
    teams: table_teams,
    notifications: table_notifications,
    plan_uploads: table_plan_uploads,
    working_set_plans: table_working_set_plans,
    my_deliveries: table_my_deliveries,
    public_deliveries: table_public_deliveries,
    plan_approvals: table_plan_approvals,
    specification_approvals: table_specification_approvals,
    current_set_plans: table_current_set_plans,
    delivery_plans: table_delivery_plans,
    import_spreadsheet: table_import_spreadsheet,
    delivery_specifications: table_delivery_specifications,
    all_tasks: table_all_tasks,
    brands: table_brands,
    public_brands: table_public_brands,
    products: table_products,
    working_set_specifications: table_working_set_specifications,
    specification_sets: table_specification_sets,
    current_set_specifications: table_current_set_specifications,
    my_specification_deliveries: table_my_specification_deliveries,
    public_specification_deliveries: table_public_specification_deliveries,
    documents: table_documents,
    all_meetings: table_all_meetings,
    sites: table_sites,
    buildings: table_buildings,
    storeys: table_storeys,
    zones: table_zones,
    expansion_joints: table_expansion_joints,
    entrances: table_entrances,
    rooms: table_rooms,
    room_groups: table_room_groups,
    room_types: table_room_types,
    ocr_templates: table_ocr_templates,
    working_set_boq: table_working_set_boq,
    tenders: table_tenders,
    all_shared_models: table_all_shared_models,
    superadmin_companies,
    superadmin_users,
    superadmin_materials,
    superadmin_raw_materials,
    superadmin_languages,
    superadmin_translations,
    superadmin_note_categories,
    superadmin_notes,
    superadmin_colors,
    superadmin_paper_sizes,
    superadmin_product_attribute_template_groups,
    superadmin_product_attribute_template_fields,
    superadmin_countries,
    superadmin_units,
    superadmin_regions,
    superadmin_unit_groups,
    superadmin_building_types,
    superadmin_functional_spaces,
    superadmin_disciplines,
    superadmin_raw_colors,
    superadmin_product_categories,
    superadmin_product_groups,
    superadmin_product_subgroups,
    superadmin_specification_groups,
    superadmin_specification_subgroups,
    superadmin_boq_template_groups,
    superadmin_boq_template_subgroups,
    superadmin_boq_template_calculations,
    superadmin_boq_supply_types,
    superadmin_boq_supply_type_groups,
    superadmin_filters,
    superadmin_products,
  }),
};
